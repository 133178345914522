import React from 'react';
import TestimonialItem from "../Testimonials/home-one/TestimonialItem";
import "../componant.css";
import TestimonialData from '../../data/Testimonials/home-one';
import SlickSlider from "../UI/Slick/index";

function Testimonial(){
    const settings = {
        slidesToShow: 2,
        arrows: false,
        dots: true,
        className: "testimonial-content--3 testimonial-grid",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }
    return(
        <div style={{backgroundColor:'#eff2f6'}}>
            <div className="container py-5">
                <div className='testi-left'>
                    <div className="">
                        <h3 className='title-color'>What Our Employees Say</h3>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-12 m-auto">
                            <div className="testimonial-content-wrap m-0 pl-0">
                                <SlickSlider settings={settings}>
                                    {
                                        TestimonialData.map(testimonial => (
                                            <div key={testimonial.id}>
                                                <TestimonialItem
                                                    author={testimonial.author}
                                                    // authorThumb={testimonial.authorThumb}
                                                    quote={testimonial.quote}
                                                    desig={testimonial.desig}
                                                />
                                            </div>
                                        ))
                                    }
                                </SlickSlider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default Testimonial;